import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCalendarPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const AppointDashboard = () => {
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>

        <div className="container">
				<div className="App">
        <header>
						<div className="d-flex align-items-center justify-content-center mb-2">
							<img
								src="/logoteleNew.png"
								alt="logo"
								style={{ height: '100px', width: 'auto' }}
							/>
						</div>
					</header>
        <div className="container mt-4">
            <div className="row d-flex justify-content-center">
                <div className="col-md-4 mb-4">
                    <div className="card h-100 text-center shadow-sm">
                        <div className="card-body d-flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faVideo} size="3x" className="mb-3" style={{ color: '#007bff' }} />
                            <h5 className="card-title mb-4">Join Instant Virtual Appointment</h5>
                            <button className="btn btn-primary" onClick={() => navigateTo('/home')}>Go</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card h-100 text-center shadow-sm">
                        <div className="card-body d-flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faCalendarPlus} size="3x" className="mb-3" style={{ color: '#28a745' }} />
                            <h5 className="card-title mb-4">Schedule A New Virtual Appointment</h5>
                            <button className="btn btn-success" onClick={() => navigateTo('/new-appointment')}>Go</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card h-100 text-center shadow-sm">
                        <div className="card-body d-flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" className="mb-3" style={{ color: '#17a2b8' }} />
                            <h5 className="card-title mb-4">View Scheduled Virtual Appointments</h5>
                            <button className="btn btn-info" onClick={() => navigateTo('/all-meetings')}>Go</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
					<div className="d-flex align-items-center justify-content-center mt-4 pt-4">
						<div>
							{/* <p>© 2024 Priority Care</p> */}
							<div>
								<img
									src="/footertele.png"
									alt="logo"
									style={{ height: '40px', width: 'auto' }}
								/>
							</div>
						</div>
					</div>
				</footer>
			</div>
            </div>
            </div>
    );
};

export default AppointDashboard;
