import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { configTemplate } from "../config";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import useFetchJWT from "../hook/useFetchJWT";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import axiosInstance from "../axiosInstance"

function Meeting() {
  const [searchParams] = useSearchParams();
  const sessionName = searchParams.get("_sName");
  const userName = searchParams.get("_uName");
  const navigate = useNavigate();
  const { jwt } = useFetchJWT(sessionName, userName);
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const config = useMemo(
    () => ({ ...configTemplate, sessionName, userName, videoSDKJWT: jwt }),
    [jwt, sessionName, userName]
  );

  useEffect(() => {
    // Verify if the session exists
    const verifySession = async () => {
      try {
        // const response = await axios.post('/api/verifySession', { sessionName });
        // /api/joinMeeting/fce1f105-79f4-4f8b-b674-f6a6231cc56a
        const response = await axiosInstance.get(
          // `https://baptisthealthservices.com/api/session/${sessionName}`,
          `api/session/${sessionName}`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors", // Enable CORS mode
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const savedSession = response.data._sName;
        if (!response.data?.joinMeeting === savedSession) {
          navigate("/not-active");
        }
      } catch (error) {
        console.error("Failed to verify session:", error);
        navigate("/not-active");
      }
    };

    if (sessionName) {
      verifySession();
    } else {
      navigate("/not-active");
    }
  }, [sessionName, navigate, token]);

  // Function to handle mutations
  function handleMutations(mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList") {
        setButtonTitles();
      }
    }
  }

  // Function to set titles for buttons
  function setButtonTitles() {
    const ct = document.getElementsByClassName("controls");
    const controls = ct?.[0];

    if (controls?.children && controls?.children.length > 0) {
      // Assign each button to a variable
      const audioButton = controls?.children[0];
      const videoButton = controls?.children[1];
      const shareButton = controls?.children[2];
      const userButton = controls?.children[3];
      const chatButton = controls?.children[4];
      const settingButton = controls?.children[5];

      if (audioButton) {
        audioButton.setAttribute("data-tooltip-id", "my-tooltip-1");
      }
      if (videoButton) {
        videoButton.setAttribute("data-tooltip-id", "my-tooltip-2");
      }
      if (shareButton) {
        shareButton.setAttribute("data-tooltip-id", "my-tooltip-3");
      }
      if (userButton) {
        userButton.setAttribute("data-tooltip-id", "my-tooltip-4");
      }
      if (chatButton) {
        chatButton.setAttribute("data-tooltip-id", "my-tooltip-5");
      }
      if (settingButton) {
        settingButton.setAttribute("data-tooltip-id", "my-tooltip-6");
      }
    } else {
      console.log("No buttons found or not enough buttons");
    }
  }

  useEffect(() => {
    const sessionContainer = document.getElementById("sessionContainer");

    if (jwt && sessionContainer) {
      uitoolkit.joinSession(sessionContainer, config);

      uitoolkit.onSessionJoined(() => {
        setTimeout(() => {
          const targetNode = document.querySelector(".controls");
          const observer = new MutationObserver(handleMutations);
          observer.observe(targetNode, { childList: true, subtree: true });

          const ct = document.getElementsByClassName("controls");
          const controls = ct?.[0];
          const videoButton = controls?.children?.[1];

          const lt = document.getElementsByClassName("leaveend");
          const lcontrols = lt?.[0];
          const close = lcontrols?.children?.[0];
          if (close) {
            close.setAttribute("data-tooltip-id", "my-tooltip-close");
            close.addEventListener("click", handleEndMeeting);
          }
          if (videoButton) {
            videoButton.click();
          }
        }, 200);
      });
      uitoolkit.onSessionClosed(() => {
        if (sessionContainer) {
          uitoolkit.closeSession(sessionContainer);
          uitoolkit.offSessionClosed(sessionContainer);
        }
        if (token) {
          navigate("/home");
        } else {
          setShowThankYouMessage(true);
        }
      });
    }

    return () => {
      const container = document.getElementById("sessionContainer");
      if (container) {
        uitoolkit.closeSession(container);
      }
    };
  }, [jwt, config, navigate, token]);

  const handleEndMeeting = async () => {
    if (token) {
      try {
        await axiosInstance.delete(
          // `https://baptisthealthservices.com/api/session/${sessionName}`,
          `api/session/${sessionName}`,
          {
            headers: {
              "Content-Type": "application/json",
              mode: "cors", // Enable CORS mode
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Failed to end session:", error);
      }
    }
  };

  if (showThankYouMessage) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="d-flex flex-column">
          <header>
            <div className="d-flex align-items-center justify-content-center mb-2">
              <img
                src="/logoteleNew.png"
                alt="logo"
                // style={{ height: '150px', width: 'auto' }}
                style={{ height: "55px", width: "auto" }}
              />
            </div>
          </header>
          <h4 className="mt-0 p-2 pr-2 pt-2">
            Thank you for attending your virtual appointment. Your session has been ended. 
            If you have any further questions, please contact your healthcare provider.
          </h4>
          <footer>
            <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
              <div className="mt-4 pt-4">
                {/* <p>© 2024 Priority Care</p> */}
                <div>
                  <img
                    src="/footertele.png"
                    alt="logo"
                    style={{ height: "40px", width: "auto" }}
                  />
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="container pt-5 pt-lg-0"
        style={{
          position: "relative",
          minHeight: "100vh",
          maxWidth: "68.875rem",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          id="sessionContainer"
          style={{ height: "100%", minHeight: "95vh" }}
        ></div>
        <img
          src="/logotele.png"
          alt="logo"
          style={{
            height: "50px",
            width: "auto",
            zIndex: 9999999,
            position: "absolute",
            top: "2rem",
            left: "2rem",
          }}
        />
        <img
          src="/footertele.png"
          alt="logo"
          style={{
            height: "30px",
            width: "auto",
            zIndex: 9999999,
            position: "absolute",
            top: "3rem",
            right: "2rem",
          }}
        />
      </div>

      <ReactTooltip
        variant="info"
        id="my-tooltip-1"
        place="bottom"
        content="Audio"
      />
      <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        variant="info"
        content="Video"
      />
      <ReactTooltip
        id="my-tooltip-3"
        place="bottom"
        variant="info"
        content="Share Screen"
      />
      <ReactTooltip
        id="my-tooltip-4"
        place="bottom"
        variant="info"
        content="Users"
      />
      <ReactTooltip
        id="my-tooltip-5"
        place="bottom"
        variant="info"
        content="Chat"
      />
      <ReactTooltip
        id="my-tooltip-6"
        place="bottom"
        variant="info"
        content="Settings"
      />
      <ReactTooltip
        id="my-tooltip-close"
        place="bottom"
        variant="info"
        content="End Appointment"
      />
    </>
  );
}

export default Meeting;
