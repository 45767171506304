import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';
import Meeting from './pages/Meeting';
import Login from './pages/Login';
import NotActive from './pages/NotActive';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import './css/design.css';
import MeetingsTable from './pages/AllMeetings';
import NewScheduledMeeting from './pages/NewMeeting';
import AppointDashboard from './pages/AppointmentDashboard';
import ScheduleMeet from './pages/ScheduleMeetPage';
import EndMeeting from './pages/EndMeeting';

function App() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token") || localStorage.getItem("token");

    useEffect(() => {
        if (!token && window.location.pathname === '/home') {
            navigate('/');
        }
    }, [token, navigate]);

    const ProtectedRoute = ({ children }) => {
        if (!token) {
            console.log("No token found, redirecting to login");
            return <Navigate to="/" replace />;
        }
        console.log("Token found, rendering protected route");
        return children;
    };

    return (
        <Routes>
            <Route path='/' element={<Login />} />           
            <Route path='/home' element={
                <ProtectedRoute>
                    <Home />
                </ProtectedRoute>
            } />
            <Route path='/appointment-dashboard' element={
                <ProtectedRoute>
                    <AppointDashboard />
                </ProtectedRoute>} />
            <Route path='/all-meetings' element={
                <ProtectedRoute>
                    <MeetingsTable />
                </ProtectedRoute>
            } />
            <Route path='/scheduled-meeting' element={
                <ProtectedRoute>
                <NewScheduledMeeting />
                </ProtectedRoute>
            } />
            <Route path='/new-appointment' element={
                <ProtectedRoute>
                <ScheduleMeet />
                </ProtectedRoute>
            } />
            <Route path='/meeting' element={<Meeting />} />
            <Route path='/not-active' element={<NotActive />} />
            <Route path='/end-meeting' element={<EndMeeting />} />
        </Routes>
    );
}

export default App;
