// src/config.js
// export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'http://3.233.60.35:4000/';
// export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'http://localhost:4000/api/gettoken';
// export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'https://baptisthealthservices.com/api/gettoken';
// REACT_APP_BASEURL=http://44.223.194.81:4000/
// export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'http://44.223.194.81:4000/api/gettoken';

// export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'https://dev.baptisthealthservices.com/api/gettoken';

export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || 'https://baptisthealthservices.com/api/gettoken';


export const configTemplate = {
    videoSDKJWT: '',
    sessionName: '',
    userName: '',
    sessionPasscode: '123',
    features: ['video', 'audio', 'settings', 'users', 'chat', 'share'], 
};
export const role = 1;