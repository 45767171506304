import axios from "axios";
import { useNavigate } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_BASEURL;

const getToken = async () => {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
};

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    mode: 'cors',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const navigate = useNavigate();

      // Perform logout action
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      // Optionally, clear other user data
      localStorage.clear();
      sessionStorage.clear();
      // Redirect to login page
      // window.location.href = '/';
      navigate("/");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
