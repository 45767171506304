import React from "react";

function EndMeeting() {
  return (
    <section className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <div className="container text-center mb-4 pb-4">
        <header className="mb-0">
          <img src="/logoteleNew.png" alt="logo" style={{ height: "100px", width: "auto" }} />
        </header>
        <div className="mt-0 pt-0 mb-2">
          <h4 className="ps-2 pr-2 pt-2">
          Thank you for attending your virtual appointment. Your session has been ended. 
          If you have any further questions, please contact your healthcare provider.
          </h4>
        </div>
        <footer className="mt-4 pt-4 mb-4 pb-4">
          <img src="/footertele.png" alt="logo" style={{ height: "40px", width: "auto" }} />
        </footer>
      </div>
    </section>
  );
}

export default EndMeeting;
