import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";

function ScheduleMeetPopup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const id = uuidv4();
  const [formData, setFormData] = useState({
    patientName: "",
    patientPhone: "",
    yourName: "",
    nursePhone: "",
    topic: "",
    scheduleDateTime: new Date().toISOString(), // Example initialization for scheduleDateTime
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const postResponse = await axiosInstance.post("api/create-meeting", {
        // agenda: formData.agenda,
        topic: formData.topic,
        duration: parseInt(formData.duration),
        patientName: formData.patientName,
        patientPhone: formData.patientPhone,
        nurseName: formData.nurseName,
        nursePhone: formData.nursePhone,
        start_time: formData.scheduleDateTime,
        joining_link: id,
      });
  
      if (postResponse.status === 200 || postResponse.status === 201) {
        toast.success('SMS sent successfully');
        // window.location.href = '/all-meetings';
        navigate("/all-meetings")
      } else {
        console.error('Error');
        toast.error('Please check the details you provided');
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send SMS, Please check all details you provided");
    } finally {
      setLoading(false);
    }
  };
  

  // const gotAllMeetings = () => {
  //   window.location.href = "/all-meetings";
  // };

  return (
    <section>
      <ToastContainer autoClose={8000} />
      <div
        className="d-flex justify-content-center align-items-center"
      >
        <div className="container">
          <div className="App">
            <div className="d-flex justify-content-center align-items-center">
              <div className="card" style={{ width: "30rem" }}>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex gap-2">
                      <div className="form-group mb-2">
                        <label htmlFor="yourName">Topic:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="topic"
                          name="topic"
                          value={formData.topic}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="form-group mb-2">
                        <label htmlFor="agenda">Meeting Agenda:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="agenda"
                          name="agenda"
                          value={formData.agenda}
                          onChange={handleChange}
                        />
                      </div> */}
                    </div>
                    <div className="d-flex gap-2">
                      <div className="form-group mb-2">
                        <label htmlFor="yourName">Nurse Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="yourName"
                          name="nurseName"
                          value={formData.nurseName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="nursePhone">
                          Nurse's Phone Number:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nursePhone"
                          name="nursePhone"
                          value={formData.nursePhone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <div className="form-group mb-2">
                        <label htmlFor="patientName">Patient's Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="patientName"
                          name="patientName"
                          value={formData.patientName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="patientPhone">
                          Patient's Phone Number:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="patientPhone"
                          name="patientPhone"
                          value={formData.patientPhone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <div className="form-group mb-2">
                        <label htmlFor="yourName">Duration:</label>
                        <input
                          type="number"
                          className="form-control"
                          id="duration"
                          name="duration"
                          placeholder="60 Minutes"
                          value={formData.duration}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="scheduleDateTime">
                          Schedule date and time
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="scheduleDateTime"
                          name="scheduleDateTime"
                          value={formData.scheduleDateTime}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-block mt-3 align-items-center"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send Appointment Invite"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScheduleMeetPopup;