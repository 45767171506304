import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../axiosInstance";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ScheduleMeet.css';
import { useNavigate } from 'react-router-dom';

function ScheduleMeet() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    patientName: "",
    patientPhone: "",
    nurseName: "",
    nursePhone: "",
    topic: "",
    duration: "15",
    customDuration: "",
    scheduleDate: null,
    scheduleTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      scheduleDate: date,
    }));
  };

  const validateForm = () => {
    const { patientName, patientPhone, nurseName, nursePhone, topic, duration, customDuration, scheduleDate, scheduleTime } = formData;
    if (!patientName || !patientPhone || !nurseName || !nursePhone || !topic || !duration || !scheduleDate || !scheduleTime) {
      toast.error("All fields are required");
      return false;
    }
    if (!/^\d{10}$/.test(patientPhone) || !/^\d{10}$/.test(nursePhone)) {
      toast.error("Phone numbers must be 10 digits");
      return false;
    }
    if (duration === "custom" && (isNaN(customDuration) || customDuration <= 0)) {
      toast.error("Custom duration must be a positive number");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    const duration = formData.duration === "custom" ? parseInt(formData.customDuration) : parseInt(formData.duration);
    const formattedDate = formData.scheduleDate.toLocaleDateString('en-CA');
    const scheduleDateTime = `${formattedDate}T${formData.scheduleTime}`;

    try {
      const postResponse = await axiosInstance.post("api/create-meeting", {
        topic: formData.topic,
        duration: duration,
        patientName: formData.patientName,
        patientPhone: formData.patientPhone,
        nurseName: formData.nurseName,
        nursePhone: formData.nursePhone,
        start_time: scheduleDateTime,
      });

      if (postResponse.status === 200 || postResponse.status === 201) {
        toast.success('Appointment scheduled successfully');
        setTimeout(() => {
          navigate("/all-meetings");
        }, 2000);
      } else {
        console.error('Error', postResponse);
        toast.error('Please check the details you provided');
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Check the details you entered");
    } finally {
      setLoading(false);
    }
  };

  const backToMeetings = () => {
    navigate("/appointment-dashboard");
  };

  return (
    <>      
      <ToastContainer autoClose={4000} />
      <section>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="container">
            <div className="App">
              <header>
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <img
                    src="/logoteleNew.png"
                    alt="logo"
                    style={{ height: "100px", width: "auto" }}
                  />
                </div>
              </header>
              <div className="d-flex align-items-center justify-content-between">
                <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={backToMeetings}>&#8592; Back</button>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="card" style={{ width: "30rem" }}>
                  <div className="card-body">
                    <h5 className="card-title text-center mb-3">
                      Schedule a new virtual appointment
                    </h5>
                    <form onSubmit={handleSubmit} className="w-100">
                      <div className="w-100">
                        <div className="form-group">
                          <label htmlFor="topic">Topic:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="topic"
                            name="topic"
                            value={formData.topic}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 w-100">
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="nurseName">Nurse Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nurseName"
                            name="nurseName"
                            value={formData.nurseName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="nursePhone">Nurse's Phone Number:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nursePhone"
                            name="nursePhone"
                            value={formData.nursePhone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 w-100">
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="patientName">Patient's Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="patientName"
                            name="patientName"
                            value={formData.patientName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="patientPhone">Patient's Phone Number:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="patientPhone"
                            name="patientPhone"
                            value={formData.patientPhone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 w-100">
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="scheduleDate">Schedule Date:</label>
                          <DatePicker
                            selected={formData.scheduleDate}
                            onChange={handleDateChange}
                            placeholderText="mm-dd-yyyy"
                            dateFormat="MM/dd/yyyy"
                            className="form-control schedule-meetDate"
                            id="scheduleDate"
                            name="scheduleDate"
                          />
                        </div>
                        <div className="form-group mb-2 w-50">
                          <label htmlFor="scheduleTime">Schedule Time:</label>
                          <input
                            type="time"
                            className="form-control w-100"
                            id="scheduleTime"
                            name="scheduleTime"
                            value={formData.scheduleTime}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 w-100">
                        <label htmlFor="duration">Select Duration:</label>
                        <select
                          className="form-select"
                          id="duration"
                          name="duration"
                          value={formData.duration}
                          onChange={handleChange}
                        >
                          <option value="15">15 minutes</option>
                          <option value="20">20 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="45">45 minutes</option>
                          <option value="60">1 hour</option>
                          <option value="custom">Custom</option>
                        </select>
                        {formData.duration === "custom" && (
                          <div className="mt-2">
                            <label htmlFor="customDuration">Duration (minutes):</label>
                            <input
                              type="number"
                              className="form-control"
                              id="customDuration"
                              name="customDuration"
                              value={formData.customDuration}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-3 w-100"
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Send Appointment Invite"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <footer className="pb-4">
              <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
                <div>
                  <div>
                    <img
                      src="/footertele.png"
                      alt="logo"
                      style={{ height: "40px", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </>
  );
}

export default ScheduleMeet;

// import React, { useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axiosInstance from "../axiosInstance";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './ScheduleMeet.css';
// import { useNavigate } from 'react-router-dom';

// function ScheduleMeet() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     patientName: "",
//     patientPhone: "",
//     nurseName: "",
//     nursePhone: "",
//     topic: "",
//     duration: "15",
//     scheduleDate: null,
//     scheduleTime: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleDateChange = (date) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       scheduleDate: date,
//     }));
//   };

//   const validateForm = () => {
//     const { patientName, patientPhone, nurseName, nursePhone, topic, duration, scheduleDate, scheduleTime } = formData;
//     if (!patientName || !patientPhone || !nurseName || !nursePhone || !topic || !duration || !scheduleDate || !scheduleTime) {
//       toast.error("All fields are required");
//       return false;
//     }
//     if (!/^\d{10}$/.test(patientPhone) || !/^\d{10}$/.test(nursePhone)) {
//       toast.error("Phone numbers must be 10 digits");
//       return false;
//     }
//     if (isNaN(duration) || duration <= 0) {
//       toast.error("Duration must be a positive number");
//       return false;
//     }
//     return true;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) {
//       return;
//     }
//     setLoading(true);

//     const formattedDate = formData.scheduleDate.toLocaleDateString('en-CA');
//     const scheduleDateTime = `${formattedDate}T${formData.scheduleTime}`;

//     try {
//       const postResponse = await axiosInstance.post("api/create-meeting", {
//         topic: formData.topic,
//         duration: parseInt(formData.duration),
//         patientName: formData.patientName,
//         patientPhone: formData.patientPhone,
//         nurseName: formData.nurseName,
//         nursePhone: formData.nursePhone,
//         start_time: scheduleDateTime,
//       });

//       if (postResponse.status === 200 || postResponse.status === 201) {
//         toast.success('Appointment scheduled successfully');
//         setTimeout(() => {
//           navigate("/all-meetings");
//         }, 2000);
//       } else {
//         console.error('Error', postResponse);
//         toast.error('Please check the details you provided');
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Check the details you entered");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const backToMeetings = () => {
//     navigate("/appointment-dashboard");
//   };

//   return (
//     <>      
//     <ToastContainer autoClose={4000} />
//     <section>
//       <div
//         className="d-flex justify-content-center align-items-center"
//         style={{ height: "100vh" }}
//       >
//         <div className="container">
//           <div className="App">
//             <header>
//               <div className="d-flex align-items-center justify-content-center mb-2">
//                 <img
//                   src="/logoteleNew.png"
//                   alt="logo"
//                   style={{ height: "100px", width: "auto" }}
//                 />
//               </div>
//             </header>
//             <div className="d-flex align-items-center justify-content-between">
//               <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={backToMeetings}>&#8592; Back</button>
//             </div>
//             <div className="d-flex justify-content-center align-items-center mt-4">
//               <div className="card" style={{ width: "30rem" }}>
//                 <div className="card-body">
//                   <h5 className="card-title text-center mb-3">
//                     Schedule a new virtual appointment
//                   </h5>
//                   <form onSubmit={handleSubmit} className="w-100">
//                     <div className="w-100">
//                       <div className="form-group">
//                         <label htmlFor="topic">Topic:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="topic"
//                           name="topic"
//                           value={formData.topic}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nurseName">Nurse Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nurseName"
//                           name="nurseName"
//                           value={formData.nurseName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nursePhone">Nurse's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nursePhone"
//                           name="nursePhone"
//                           value={formData.nursePhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientName">Patient's Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientName"
//                           name="patientName"
//                           value={formData.patientName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientPhone">Patient's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientPhone"
//                           name="patientPhone"
//                           value={formData.patientPhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="scheduleDate">Schedule Date:</label>
//                         <DatePicker
//                           selected={formData.scheduleDate}
//                           onChange={handleDateChange}
//                           placeholderText="mm-dd-yyyy"
//                           dateFormat="MM/dd/yyyy"
//                           className="form-control schedule-meetDate"
//                           id="scheduleDate"
//                           name="scheduleDate"
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="scheduleTime">Schedule Time:</label>
//                         <input
//                           type="time"
//                           className="form-control w-100"
//                           id="scheduleTime"
//                           name="scheduleTime"
//                           value={formData.scheduleTime}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="form-group mb-2 w-100">
//                       <label htmlFor="duration">Select Duration:</label>
//                       {/* <input
//                         type="number"
//                         className="form-control"
//                         id="duration"
//                         name="duration"
//                         value={formData.duration}
//                         onChange={handleChange}
//                       /> */}
//                       <select
//                           className="form-select"
//                           id="duration"
//                           name="duration"
//                           value={formData.duration}
//                           onChange={handleChange}
//                         >
//                           <option value="15">15 minutes</option>
//                           <option value="20">20 minutes</option>
//                           <option value="30">30 minutes</option>
//                           <option value="45">45 minutes</option>
//                           <option value="60">1 hour</option>
//                           <option value="custom">Custom</option>
//                         </select>
//                         {
//                           formData.duration === "custom" && 
//                           <div className="mt-2">
//                           <label htmlFor="duration">Duration (minutes):</label>
//                             <input
//                               type="number"
//                               className="form-control"
//                               id="duration"
//                               name="duration"
//                               value={formData.duration}
//                               onChange={handleChange}
//                             />
//                           </div>
//                         }
//                     </div>
//                     <button
//                       type="submit"
//                       className="btn btn-primary btn-block mt-3 w-100"
//                       disabled={loading}
//                     >
//                       {loading ? "Sending..." : "Send Appointment Invite"}
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <footer className="pb-4">
//             <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//               <div>
//                 <div>
//                   <img
//                     src="/footertele.png"
//                     alt="logo"
//                     style={{ height: "40px", width: "auto" }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </footer>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// }

// export default ScheduleMeet;


// import React, { useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axiosInstance from "../axiosInstance";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './ScheduleMeet.css'
// import { useNavigate } from 'react-router-dom';

// function ScheduleMeet() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     patientName: "",
//     patientPhone: "",
//     yourName: "",
//     nursePhone: "",
//     topic: "",
//     duration: "40", // Add this to match your form fields
//     scheduleDate: null, // For separate date input
//     scheduleTime: "", // For separate time input
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleDateChange = (date) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       scheduleDate: date,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
  
//     // Manually format the date to avoid timezone issues
//     const formattedDate = formData.scheduleDate.toLocaleDateString('en-CA'); // Formats date as YYYY-MM-DD
//     const scheduleDateTime = `${formattedDate}T${formData.scheduleTime}`;
  
//     try {
//       const postResponse = await axiosInstance.post("api/create-meeting", {
//         topic: formData.topic,
//         duration: parseInt(formData.duration),
//         patientName: formData.patientName,
//         patientPhone: formData.patientPhone,
//         nurseName: formData.nurseName,
//         nursePhone: formData.nursePhone,
//         start_time: scheduleDateTime,
//       });
  
//       if (postResponse.status === 200 || postResponse.status === 201) {
//         toast.success('Appointment scheduled successfully');
//         setTimeout(() => {
//           navigate("/all-meetings");
//         }, 2000);
//       } else {
//         console.error('Error', postResponse);
//         toast.error('Please check the details you provided');
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Check the details you entered");
//     } finally {
//       setLoading(false);
//     }
//   };
  
  
//   // const gotAllMeetings = () => {
//   //   window.location.href = "/all-meetings";
//   // };

//   const backToMeetings = () => {
//     // window.location.href = '/appointment-dashboard';
//     navigate("/appointment-dashboard");
//   };

//   return (
//     <>      
//     <ToastContainer autoClose={4000} />
//     <section>
//       <div
//         className="d-flex justify-content-center align-items-center"
//         style={{ height: "100vh" }}
//       >
//         <div className="container">
//           <div className="App">
//             <header>
//               <div className="d-flex align-items-center justify-content-center mb-2">
//                 <img
//                   src="/logoteleNew.png"
//                   alt="logo"
//                   style={{ height: "100px", width: "auto" }}
//                 />
//               </div>
//             </header>
//             <div className="d-flex align-items-center justify-content-between">
//               <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={backToMeetings}>&#8592; Back</button>
//               {/* <button
//                 className="float-right page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal"
//                 onClick={gotAllMeetings}
//               >
//                 View scheduled meetings &#8594;
//               </button> */}
//             </div>
//             <div className="d-flex justify-content-center align-items-center mt-4">
//               <div className="card" style={{ width: "30rem" }}>
//                 <div className="card-body">
//                   <h5 className="card-title text-center mb-3">
//                     Schedule a new virtual appointment
//                   </h5>
//                   <form onSubmit={handleSubmit} className="w-100">
//                     <div className="w-100">
//                       <div className="form-group">
//                         <label htmlFor="topic">Topic:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="topic"
//                           name="topic"
//                           value={formData.topic}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nurseName">Nurse Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nurseName"
//                           name="nurseName"
//                           value={formData.nurseName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nursePhone">Nurse's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nursePhone"
//                           name="nursePhone"
//                           value={formData.nursePhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientName">Patient's Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientName"
//                           name="patientName"
//                           value={formData.patientName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientPhone">Patient's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientPhone"
//                           name="patientPhone"
//                           value={formData.patientPhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="scheduleDate">Schedule Date:</label>
//                         <DatePicker
//                           selected={formData.scheduleDate}
//                           onChange={handleDateChange}
//                           placeholderText="mm-dd-yyyy"
//                           dateFormat="MM/dd/yyyy"
//                           className="form-control schedule-meetDate"
//                           id="scheduleDate"
//                           name="scheduleDate"
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="scheduleTime">Schedule Time:</label>
//                         <input
//                           type="time"
//                           className="form-control w-100"
//                           id="scheduleTime"
//                           name="scheduleTime"
//                           value={formData.scheduleTime}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="form-group mb-2 w-100">
//                       <label htmlFor="duration">Duration (Minutes):</label>
//                       <input
//                         type="number"
//                         className="form-control"
//                         id="duration"
//                         name="duration"
//                         // placeholder="60"
//                         value={formData.duration}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <button
//                       type="submit"
//                       className="btn btn-primary btn-block mt-3 w-100"
//                       disabled={loading}
//                     >
//                       {loading ? "Sending..." : "Send Appointment Invite"}
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <footer className="pb-4">
//             <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//               <div>
//                 <div>
//                   <img
//                     src="/footertele.png"
//                     alt="logo"
//                     style={{ height: "40px", width: "auto" }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </footer>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// }

// export default ScheduleMeet;


// import React, { useState } from "react";
// // import { v4 as uuidv4 } from 'uuid';
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axiosInstance from "../axiosInstance";

// function ScheduleMeet() {
//   const [loading, setLoading] = useState(false);
//   // const id = uuidv4();
//   const [formData, setFormData] = useState({
//     patientName: "",
//     patientPhone: "",
//     yourName: "",
//     nursePhone: "",
//     topic: "",
//     scheduleDateTime: new Date().toISOString(), // Example initialization for scheduleDateTime
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
  
//     try {
//       const postResponse = await axiosInstance.post("api/create-meeting", {
//         // agenda: formData.agenda,
//         topic: formData.topic,
//         duration: parseInt(formData.duration),
//         patientName: formData.patientName,
//         patientPhone: formData.patientPhone,
//         nurseName: formData.nurseName,
//         nursePhone: formData.nursePhone,
//         start_time: formData.scheduleDateTime,
//       });
  
//       if (postResponse.status === 200 || postResponse.status === 201) {
//         toast.success('SMS sent successfully');
//         window.location.href = '/all-meetings';
//       } else {
//         console.error('Error');
//         toast.error('Please check the details you provided');
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Failed to send SMS");
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const gotAllMeetings = () => {
//     window.location.href = "/all-meetings";
//   };

//   const backToMeetings = () => {
//     window.location.href = '/appointment-dashboard';
//   };

//   return (
//     <section>
//       <ToastContainer autoClose={8000} />
//       <div
//         className="d-flex justify-content-center align-items-center"
//         style={{ height: "100vh" }}
//       >
//         <div className="container">
//           <div className="App">
//             <header>
//               <div className="d-flex align-items-center justify-content-center mb-2">
//                 <img
//                   src="/logoteleNew.png"
//                   alt="logo"
//                   style={{ height: "100px", width: "auto" }}
//                 />
//               </div>
//             </header>
//             <div className="d-flex align-items-center justify-content-between">
//               <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal mb-4" onClick={backToMeetings}>&#8592; Back</button>
//               <button
//                 className="float-right page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal mb-4"
//                 onClick={gotAllMeetings}
//               >
//                 {" "}
//                 View scheduled meetings &#8594;
//               </button>
//             </div>
//             {/* <button className="btn btn-link text-right page-link" onClick={gotAllMeetings} style={{ float: 'right' }}>
//               View all meetings &#8594;
//             </button> */}
//             <div className="d-flex justify-content-center align-items-center mt-4">
//               <div className="card" style={{ width: "30rem" }}>
//                 <div className="card-body">
//                   <h5 className="card-title text-center mb-3">
//                     Schedule a new virtual appointment
//                   </h5>
//                   <form onSubmit={handleSubmit} className="w-100">
//                     <div className="w-100">
//                       <div className="form-group">
//                         <label htmlFor="topic">Topic:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="topic"
//                           name="topic"
//                           value={formData.topic}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nurseName">Nurse Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nurseName"
//                           name="nurseName"
//                           value={formData.nurseName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="nursePhone">Nurse's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="nursePhone"
//                           name="nursePhone"
//                           value={formData.nursePhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientName">Patient's Name:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientName"
//                           name="patientName"
//                           value={formData.patientName}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="patientPhone">Patient's Phone Number:</label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="patientPhone"
//                           name="patientPhone"
//                           value={formData.patientPhone}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="d-flex gap-2 w-100">
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="duration">Duration (Minutes):</label>
//                         <input
//                           type="number"
//                           className="form-control"
//                           id="duration"
//                           name="duration"
//                           placeholder="60"
//                           value={formData.duration}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="form-group mb-2 w-50">
//                         <label htmlFor="scheduleDateTime">Schedule date and time:</label>
//                         <input
//                           type="datetime-local"
//                           className="form-control"
//                           id="scheduleDateTime"
//                           name="scheduleDateTime"
//                           value={formData.scheduleDateTime}
//                           onChange={handleChange}
//                         />
//                       </div>
//                     </div>

//                     <button
//                       type="submit"
//                       className="btn btn-primary btn-block mt-3 w-100"
//                       disabled={loading}
//                     >
//                       {loading ? "Sending..." : "Send Appointment Invite"}
//                     </button>
//                   </form>

//                 </div>
//               </div>
//             </div>
//           </div>
//           <footer>
//             <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//               <div>
//                 <div>
//                   <img
//                     src="/footertele.png"
//                     alt="logo"
//                     style={{ height: "40px", width: "auto" }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </footer>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default ScheduleMeet;