import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';
function Home() {
	const navigate = useNavigate();
	const [loading, setloading] = useState(false);
	// Redirect to meeting
	const id = uuidv4();
	const [formData, setFormData] = useState({
		patientName: '',
		patientPhone: '',
		yourName: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	let token = sessionStorage.getItem('token') || localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const response = await axiosInstance.post(
        "api/sendsms",
        {
          nurseName: formData.yourName,
          patientPhone: formData.patientPhone,
          joinMeeting: id,
          patientName: formData.patientName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      console.log('called');
      if (response.status === 200) {
        console.log('SMS sent successfully');
        setTimeout(() => {
          window.location.href = `/meeting?_sName=${id}&_uName=${formData.yourName}`;
        }, 1000);
      } else if (response.status === 401 || !token) {
        // Handle unauthorized or missing token
        // window.location.href = '/';
        return;
      } else {
        console.error('Failed to send SMS');
        toast.error('Please check the details you provided');
        setTimeout(() => {
          // window.location.href = '/home'; 
          return;
        }, 1000);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
  

//   const gotAllMeetings = () => {
//     // window.location.href = "/all-meetings";
// 	navigate("/all-meetings")
//   };

  const backToMeetings = () => {
    // window.location.href = '/appointment-dashboard';
	navigate("/appointment-dashboard")
  };

	return (
		<section>
			<ToastContainer autoClose={8000} />
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
			<div className="container">
				<div className="App">
					<header>
						<div className="d-flex align-items-center justify-content-center mb-2">
							<img
								src="/logoteleNew.png"
								alt="logo"
								style={{ height: '100px', width: 'auto' }}
							/>
						</div>
					</header>
					<div className="d-flex align-items-center justify-content-between">
						<button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal mb-4" onClick={backToMeetings}>
							&#8592; Back
						</button>
						{/* <button
							className="float-right page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal mb-4"
							onClick={gotAllMeetings}
						>
							{" "}
							View scheduled meetings &#8594;
						</button> */}
        			</div>
					<div className="d-flex justify-content-center align-items-center">
						<div className="card" style={{ width: '30rem' }}>
							<div className="card-body">
								<h5 className="card-title text-center mb-3">
									Join instant virtual appointment
								</h5>
								<form onSubmit={handleSubmit}>
									<div className="form-group mb-3">
										<label htmlFor="yourName">Nurse's Name:</label>
										<input
											type="text"
											className="form-control"
											id="yourName"
											name="yourName"
											value={formData.yourName}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label htmlFor="patientName">Patient's Name:</label>
										<input
											type="text"
											className="form-control"
											id="patientName"
											name="patientName"
											value={formData.patientName}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label htmlFor="patientPhone">
											Patient's Phone Number:
										</label>
										<input
											type="text"
											className="form-control"
											id="patientPhone"
											name="patientPhone"
											value={formData.patientPhone}
											onChange={handleChange}
										/>
									</div>
									<button
										type="submit"
										className="btn btn-primary btn-block mt-3 align-items-center"
									>
										{loading ? 'Sending...' : 'Send Appointment Invite'}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<footer>
					<div className="d-flex align-items-center justify-content-center mt-4 pt-4">
						<div>
							{/* <p>© 2024 Priority Care</p> */}
							<div>
								<img
									src="/footertele.png"
									alt="logo"
									style={{ height: '40px', width: 'auto' }}
								/>
							</div>
						</div>
					</div>
				</footer>
			</div>
            </div>
		</section>
	);
}

export default Home;
