import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../axiosInstance';
function Login() {
	const navigate = useNavigate();
	const [err, setErr] = useState('');
	const [formData, setFormData] = useState({
		userName: '',
		passWord: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  
    try {
      const response = await axiosInstance.post("/api/login", {
        userName: formData.userName,
        passWord: formData.passWord,
      });
  
      if (response.status === 200) {
        const data = response.data;
        console.log('Login successful');
        localStorage.setItem('token', data.token);
        sessionStorage.setItem('token', data.token);
        setTimeout(() => {
          // navigate(`/home`);
          navigate(`/appointment-dashboard`);
        }, 500); // Delay of 500ms
        toast.success("Login successful");
      }
       else {
        console.error('Failed to login');
        setErr(response.data.error);
        toast.error(response.data.error || 'Invalid username or password');
      }
    } catch (error) {
      console.error('An error occurred:', err);
      toast.error('An error occurred during login. Please try again.');
    }
  };
  

  return (
    <section>
      <ToastContainer />
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <div className="container">
        <div >
          <header>
            <div className="d-flex align-items-center justify-content-center mb-2 mt-4">
              <img
                src="/logoteleNew.png"
                alt="logo"
                style={{ height: "100px", width: "auto" }}
              />
            </div>
          </header>
          <div className="d-flex justify-content-center align-items-center flex-direction-column">
            {/* <p style={{ color: "red" }}>{err}</p> */}
            <div className="card" style={{ width: "30rem" }}>
              <div className="card-body">
                <h5 className="card-title text-center mb-3">
                  Virtual Appointments
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="yourName">User Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      name="userName"
                      value={formData.userName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="yourName">Password:</label>
                    <input
                      type="password"
                      className="form-control"
                      id="passWord"
                      name="passWord"
                      value={formData.passWord}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary mt-1">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
            <div>
              {/* <p>© 2024 Priority Care</p> */}
              <div>
                <img
                  src="/footertele.png"
                  alt="logo"
                  style={{ height: "40px", width: "auto" }}
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      </div>
    </section>
  );
	
}

export default Login;
