import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../axiosInstance';
import ScheduleMeetPopup from './scheduleMeet';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const token = sessionStorage.getItem('token') || localStorage.getItem('token');

const fetchUpcomingMeetings = async (page, limit) => {
  try {
    const response = await axiosInstance.get(`/api/upcoming-meetings?page=${page}&limit=${limit}`);
    return response?.data;
  } catch (error) {
    console.error('Error fetching upcoming meetings:', error);
    return { upcomingMeetings: [], totalRecords: 0 };
  }
};

const fetchPreviousMeetings = async (page, limit) => {
  try {
    const response = await axiosInstance.get(`/api/past-meetings?page=${page}&limit=${limit}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching previous meetings:', error);
    return { pastMeetings: [], totalRecords: 0 };
  }
};

const MeetingsTable = () => {
  const [activeTab, setActiveTab] = useState('Upcoming');
  const [meetings, setMeetings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showScheduleMeetPopup, setShowScheduleMeetPopup] = useState(false);
  const itemsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeetings = async () => {
      const data = activeTab === 'Upcoming' 
        ? await fetchUpcomingMeetings(currentPage, itemsPerPage)
        : await fetchPreviousMeetings(currentPage, itemsPerPage);
      setMeetings(data?.upcomingMeetings || data?.pastMeetings);
      setTotalRecords(data?.totalRecords);
    };
    fetchMeetings();
  }, [activeTab, currentPage]);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const backToMeetings = () => {
    navigate("/appointment-dashboard");
  };

  const goToScheduleMeetings = () => {
    navigate("/new-appointment");
  };

  const sendReminder = async (meetingId) => {
    try {
      const response = await axiosInstance.post(`/api/send-reminder/${meetingId}`);
      if (response.status === 200) {
        toast.success('Reminder sent successfully');
      } else {
        toast.error('Error sending reminder');
      }
    } catch (error) {
      toast.error(`Error sending reminder: ${error.message}`);
    }
  };

  const currentItems = meetings.slice(0, itemsPerPage);

  const formatDate = (isoString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    };
    return new Date(isoString).toLocaleString('en-US', options);
  };

  const renderPagination = () => {
    const pages = [];

    if (currentPage > 1) {
      // Previous button
      pages.push(
        <li key="prev" className="page-item">
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
      );
    }

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }
    } else {
      pages.push(
        <li key={1} className={`page-item ${1 === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(1)}>
            1
          </button>
        </li>
      );

      if (currentPage > 3) {
        pages.push(<li key="ellipsis-start" className="page-item disabled"><span className="page-link">...</span></li>);
      }

      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      if (currentPage === 1) {
        endPage = Math.min(4, totalPages - 1);
      }

      if (currentPage === totalPages) {
        startPage = Math.max(2, totalPages - 3);
      }

      for (let i = startPage; i <= endPage+1; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }

      if (currentPage < totalPages - 2) {
        if (currentPage < totalPages - 3) {
          pages.push(<li key="ellipsis-end" className="page-item disabled"><span className="page-link">...</span></li>);
        }
        pages.push(
          <li key={totalPages} className={`page-item ${totalPages === currentPage ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </button>
          </li>
        );
      }
    }

    if (currentPage < totalPages) {
      // Next button
      pages.push(
        <li key="next" className="page-item">
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      );
    }

    return pages;
  };

  return (
    <>
      <ToastContainer autoClose={4000} />
      <div className="container my-4">
        <header>
          <div className="d-flex align-items-center justify-content-center mb-2">
            <img src="/logoteleNew.png" alt="logo" style={{ height: '100px', width: 'auto' }} />
          </div>
        </header>
        <div className="d-flex align-items-center justify-content-between">
          <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={backToMeetings}>
            &#8592; Back
          </button>
          <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={goToScheduleMeetings}>
            Schedule an appointment
          </button>
        </div>
        <h2 className="text-center mb-4 mt-4">Scheduled Meetings</h2>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button className={`nav-link fw-bold ${activeTab === 'Upcoming' ? 'active' : ''}`} onClick={() => { setActiveTab('Upcoming'); setCurrentPage(1); }}>
              Upcoming
            </button>
          </li>
          <li className="nav-item">
            <button className={`nav-link fw-bold ${activeTab === 'Previous' ? 'active' : ''}`} onClick={() => { setActiveTab('Previous'); setCurrentPage(1); }}>
              Previous
            </button>
          </li>
        </ul>
        <div className="table-responsive">
          <table className="table table-striped table-bordered text-center mt-3">
            <thead className="thead-dark">
              <tr>
                <th>Topic</th>
                <th>Patient Name</th>
                <th>Patient Phone</th>
                <th>Nurse Name</th>
                <th>Nurse Phone</th>
                <th>Schedule</th>
                <th>Duration</th>
                {activeTab === 'Upcoming' &&
                  <>
                    <th>Join Appointment</th>
                    <th>Reminder</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((meeting) => (
                <tr key={meeting?._id}>
                  <td>{meeting?.topic}</td>
                  <td>{meeting?.patientName}</td>
                  <td>{meeting?.patientPhone}</td>
                  <td>{meeting?.nurseName}</td>
                  <td>{meeting?.nursePhone}</td>
                  <td>{formatDate(meeting?.start_time)}</td>
                  <td>{meeting?.duration} minutes</td>
                  {activeTab === 'Upcoming' &&
                    <>
                      <td>
                        <a
                          //href={`http://localhost:3001/meet/scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}&role=1`}
                          href={`https://dev.baptisthealthservices.com/meet/scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}&role=1`}
                          // href={`https://baptisthealthservices.com/meet/scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}&role=1`}
                          rel="noopener noreferrer"
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Join Appointment
                        </a>
                      </td>
                      <td>
                        <button onClick={() => sendReminder(meeting?.meetingId)} className="btn btn-primary">
                          Send Reminder
                        </button>
                      </td>
                    </>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example" className="d-flex justify-content-center mt-2">
          <ul className="pagination">
            {renderPagination()}
          </ul>
        </nav>
        <footer>
          <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
            <div>
              <img src="/footertele.png" alt="logo" style={{ height: '40px', width: 'auto' }} />
            </div>
          </div>
        </footer>
      </div>
      <Modal show={showScheduleMeetPopup} onHide={() => setShowScheduleMeetPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule New Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleMeetPopup />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowScheduleMeetPopup(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MeetingsTable;



// import React, { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axiosInstance from '../axiosInstance';
// import ScheduleMeetPopup from './scheduleMeet';
// import { Modal, Button } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// // import { v4 as uuidv4 } from 'uuid';

// const token = sessionStorage.getItem('token') || localStorage.getItem('token');

// const fetchUpcomingMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(`/api/upcoming-meetings?page=${page}&limit=${limit}`);
//     return response?.data;
//   } catch (error) {
//     console.error('Error fetching upcoming meetings:', error);
//     return { upcomingMeetings: [], totalRecords: 0 };
//   }
// };

// const fetchPreviousMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(`/api/past-meetings?page=${page}&limit=${limit}`, {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching previous meetings:', error);
//     return { pastMeetings: [], totalRecords: 0 };
//   }
// };

// const MeetingsTable = () => {
//   const [activeTab, setActiveTab] = useState('Upcoming');
//   const [meetings, setMeetings] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [showScheduleMeetPopup, setShowScheduleMeetPopup] = useState(false);
//   // const [loading, setLoading] = useState(false);
//   const itemsPerPage = 5;
//   // const id = uuidv4();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchMeetings = async () => {
//       const data = activeTab === 'Upcoming' 
//         ? await fetchUpcomingMeetings(currentPage, itemsPerPage)
//         : await fetchPreviousMeetings(currentPage, itemsPerPage);
//       setMeetings(data?.upcomingMeetings || data?.pastMeetings);
//       setTotalRecords(data?.totalRecords);
//     };
//     fetchMeetings();
//   }, [activeTab, currentPage]);

//   const totalPages = Math.ceil(totalRecords / itemsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const backToMeetings = () => {
//     // window.location.href = '/appointment-dashboard';
//     navigate("/appointment-dashboard")
//   };

//   const goToScheduleMeetings = () => {
//     // setShowScheduleMeetPopup(true);
//     navigate("/new-appointment")
//   };

//   const sendReminder = async (meetingId) => {
//     try {
//       const response = await axiosInstance.post(`/api/send-reminder/${meetingId}`);
//       if (response.status === 200) {
//         toast.success('Reminder sent successfully');
//       } else {
//         toast.error('Error sending reminder');
//       }
//     } catch (error) {
//       toast.error(`Error sending reminder: ${error.message}`);
//     }
//   };

//   const currentItems = meetings.slice(0, itemsPerPage);

//   const formatDate = (isoString) => {
//     const options = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'UTC' // Ensure we are using UTC time
//     };
//     return new Date(isoString).toLocaleString('en-US', options);
//   };

//   return (
//     <>
//       <ToastContainer autoClose={4000} />
//       <div className="container my-4">
//         <header>
//           <div className="d-flex align-items-center justify-content-center mb-2">
//             <img src="/logoteleNew.png" alt="logo" style={{ height: '100px', width: 'auto' }} />
//           </div>
//         </header>
//         <div className="d-flex align-items-center justify-content-between">
//           <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={backToMeetings}>
//             &#8592; Back
//           </button>
//           <button className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal" onClick={goToScheduleMeetings}>
//             Schedule an appointment
//           </button>
//         </div>
//         <h2 className="text-center mb-4 mt-4">Scheduled Meetings</h2>
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Upcoming' ? 'active' : ''}`} onClick={() => { setActiveTab('Upcoming'); setCurrentPage(1); }}>
//               Upcoming
//             </button>
//           </li>
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Previous' ? 'active' : ''}`} onClick={() => { setActiveTab('Previous'); setCurrentPage(1); }}>
//               Previous
//             </button>
//           </li>
//         </ul>
//         <div className="table-responsive">
//           <table className="table table-striped table-bordered text-center mt-3">
//             <thead className="thead-dark">
//               <tr>
//                 <th>Topic</th>
//                 <th>Patient Name</th>
//                 <th>Patient Phone</th>
//                 <th>Nurse Name</th>
//                 <th>Nurse Phone</th>
//                 <th>Schedule</th>
//                 <th>Duration</th>
//                 {activeTab === 'Upcoming' ?
//                 <th>Join Appointment</th> : null
//                 }
//                 {activeTab === 'Upcoming' ?
//                 <th>Reminder</th> : null
//                 }
//               </tr>
//             </thead>
//             <tbody>
//               {currentItems?.map((meeting) => (
//                 <tr key={meeting?._id}>
//                   <td>{meeting?.topic}</td>
//                   <td>{meeting?.patientName}</td>
//                   <td>{meeting?.patientPhone}</td>
//                   <td>{meeting?.nurseName}</td>
//                   <td>{meeting?.nursePhone}</td>
//                   <td>{formatDate(meeting?.start_time)}</td>
//                   <td>{meeting?.duration} minutes</td>
//                   {activeTab === 'Upcoming' ?
//                   <td>
//                     {/* {meeting?.joining_link &&  */}
//                     <a
//                       // href={`scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}`}
//                       // href={`http://localhost:3001/meet/scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}&role=1`}
//                       href={`https://dev.baptisthealthservices.com/meet/scheduled-meeting?meetingId=${meeting?.meetingId}&_uName=${meeting.nurseName}&passCode=${meeting.password}&role=1`}
//                       rel="noopener noreferrer"
//                       className="btn btn-primary"
//                       target="_blank"
//                     >
//                       Join Appointment
//                     </a>
//                     {/* } */}
//                     {/* {meeting?.meetingLink &&
//                      <a href={meeting.meetingLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Join Meeting New</a>
//                     } */}
//                      </td> : null
//                   }
//                   {activeTab === 'Upcoming' ?
//                   <td>
//                     <button onClick={() => sendReminder(meeting?.meetingId)} className="btn btn-primary">
//                       Send Reminder
//                     </button>
//                   </td> : null
//                   }
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         <nav className="d-flex justify-content-center mt-2">
//           <ul className="pagination">
//             {totalPages > 0 ? (
//               [...Array(totalPages)].map((_, index) => (
//                 <li key={index + 1} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
//                   <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                     {index + 1}
//                   </button>
//                 </li>
//               ))
//             ) : (
//               <li className="page-item disabled">
//                 <span className="page-link">No data</span>
//               </li>
//             )}
//           </ul>
//         </nav>
//         <footer>
//           <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//             <div>
//               <img src="/footertele.png" alt="logo" style={{ height: '40px', width: 'auto' }} />
//             </div>
//           </div>
//         </footer>
//       </div>
//       <Modal show={showScheduleMeetPopup} onHide={() => setShowScheduleMeetPopup(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Schedule New Meeting</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ScheduleMeetPopup />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowScheduleMeetPopup(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default MeetingsTable;



// import React, { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axiosInstance from '../axiosInstance';
// import ScheduleMeet from './scheduleMeet';
// import { Modal, Button } from 'react-bootstrap';

// const token = sessionStorage.getItem('token') || localStorage.getItem('token');

// const fetchUpcomingMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(
//       `/api/upcoming-meetings?page=${page}&limit=${limit}`,
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching upcoming meetings:', error);
//     return { upcomingMeetings: [], totalRecords: 0 };
//   }
// };

// const fetchPreviousMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(`api/past-meetings?page=${page}&limit=${limit}`,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           mode: 'cors',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching previous meetings:', error);
//     return { pastMeetings: [], totalRecords: 0 };
//   }
// };

// const MeetingsTable = () => {
//   const [activeTab, setActiveTab] = useState('Upcoming');
//   const [meetings, setMeetings] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [showScheduleMeetPopup, setShowScheduleMeetPopup] = useState(false);
//   const itemsPerPage = 5;

//   useEffect(() => {
//     const fetchMeetings = async () => {
//       const data =
//         activeTab === 'Upcoming'
//           ? await fetchUpcomingMeetings(currentPage, itemsPerPage)
//           : await fetchPreviousMeetings(currentPage, itemsPerPage);
//       setMeetings(data.upcomingMeetings || data.pastMeetings);
//       setTotalRecords(data.totalRecords);
//     };
//     fetchMeetings();
//   }, [activeTab, currentPage]);

//   const totalPages = Math.ceil(totalRecords / itemsPerPage);

//   const handleClick = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const backToMeetings = () => {
//     window.location.href = '/home';
//   };

//   const goToScheduleMeetings = () =>{
//     setShowScheduleMeetPopup(true);
//   }

//   const sendReminder = async (meetingId) => {
//     try {
//       const response = await axiosInstance.post(
//         `api/send-reminder/${meetingId}`,
//       );
//       if (response.status === 200) {
//         toast.success('Reminder sent successfully');
//       } else {
//         toast.error('Error sending reminder' || response.error.message);
//       }
//     } catch (error) {
//       toast.error('Error sending reminder' || error.message);
//     }
//   };

//   const currentItems = meetings.slice(0, itemsPerPage);

//   return (
//     <>
//       <ToastContainer autoClose={8000} />
//       <div className="container my-4">
//         <header>
//           <div className="d-flex align-items-center justify-content-center mb-2">
//             <img src="/logoteleNew.png" alt="logo" style={{ height: '100px', width: 'auto' }} />
//           </div>
//         </header>
//         <div className="d-flex align-items-center justify-content-between">
//           <button
//             className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal"
//             onClick={backToMeetings}
//           >&#8592; Back</button>
//           <button
//             className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal"
//             onClick={goToScheduleMeetings}
//           >Schedule a meeting</button>
//         </div>
//         <h2 className="text-center mb-4">Scheduled Meetings</h2>
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Upcoming' ? 'active' : ''}`} onClick={() => { setActiveTab('Upcoming'); setCurrentPage(1); }}>
//               Upcoming
//             </button>
//           </li>
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Previous' ? 'active' : ''}`} onClick={() => { setActiveTab('Previous'); setCurrentPage(1); }}>
//               Previous
//             </button>
//           </li>
//         </ul>
//         <div className="table-responsive">
//         <table className="table table-striped table-bordered text-center mt-3">
//           <thead className="thead-dark">
//             <tr>
//               <th>Patient Name</th>
//               <th>Patient Phone</th>
//               <th>Nurse Name</th>
//               <th>Nurse Phone</th>
//               <th>Schedule</th>
//               <th>Duration</th>
//               <th>Join Meeting</th>
//               <th>Reminder</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentItems.map((meeting) => (
//               <tr key={meeting._id}>
//                 <td>{meeting.patientName}</td>
//                 <td>{meeting.patientPhone}</td>
//                 <td>{meeting.nurseName}</td>
//                 <td>{meeting.nursePhone}</td>
//                 <td>{new Date(meeting.start_time).toLocaleString()}</td>
//                 <td>{meeting.duration} minutes</td>
//                 <td>
//                   <a href={meeting.meetingLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Join Meeting</a>
//                 </td>
//                 <td>
//                   <button onClick={() => sendReminder(meeting._id)} className="btn btn-primary">Send Reminder</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         </div>
//         <nav className="d-flex justify-content-center">
//           <ul className="pagination">
//             {[...Array(totalPages)].map((_, index) => (
//               <li key={index + 1} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
//                 <button className="page-link" onClick={() => handleClick(index + 1)}>{index + 1}</button>
//               </li>
//             ))}
//           </ul>
//         </nav>
//         <footer>
//           <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//             <div>
//               <img src="/footertele.png" alt="logo" style={{ height: '40px', width: 'auto' }} />
//             </div>
//           </div>
//         </footer>
//       </div>
//       <Modal show={showScheduleMeetPopup} onHide={() => setShowScheduleMeetPopup(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Schedule a Meeting</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ScheduleMeet />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowScheduleMeetPopup(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default MeetingsTable;


// import React, { useState, useEffect } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axiosInstance from '../axiosInstance';
// import ScheduleMeet from './scheduleMeet';
// import { Modal, Button } from 'react-bootstrap';

// const token = sessionStorage.getItem('token') || localStorage.getItem('token');

// const fetchUpcomingMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(
//       `/api/upcoming-meetings?page=${page}&limit=${limit}`,
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching upcoming meetings:', error);
//     return { upcomingMeetings: [], totalRecords: 0 };
//   }
// };

// const fetchPreviousMeetings = async (page, limit) => {
//   try {
//     const response = await axiosInstance.get(`api/past-meetings?page=${page}&limit=${limit}`,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           mode: 'cors',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching previous meetings:', error);
//     return { pastMeetings: [], totalRecords: 0 };
//   }
// };

// const MeetingsTable = () => {
//   const [activeTab, setActiveTab] = useState('Upcoming');
//   const [meetings, setMeetings] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [showScheduleMeetPopup, setShowScheduleMeetPopup] = useState(false);
//   const itemsPerPage = 5;

//   useEffect(() => {
//     const fetchMeetings = async () => {
//       const data =
//         activeTab === 'Upcoming'
//           ? await fetchUpcomingMeetings(currentPage, itemsPerPage)
//           : await fetchPreviousMeetings(currentPage, itemsPerPage);
//       setMeetings(data.upcomingMeetings || data.pastMeetings);
//       setTotalRecords(data.totalRecords);
//     };
//     fetchMeetings();
//   }, [activeTab, currentPage]);

//   const totalPages = Math.ceil(totalRecords / itemsPerPage);

//   const handleClick = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const backToMeetings = () => {
//     window.location.href = '/home';
//   };

//   const goToScheduleMeetings = () =>{
//     setShowScheduleMeetPopup(true);
//   }

//   const sendReminder = async (meetingId) => {
//     try {
//       const response = await axiosInstance.post(
//         `api/send-reminder/${meetingId}`,
//       );
//       if (response.status === 200) {
//         toast.success('Reminder sent successfully');
//       } else {
//         toast.error('Error sending reminder' || response.error.message);
//       }
//     } catch (error) {
//       toast.error('Error sending reminder' || error.message);
//     }
//   };

//   const currentItems = meetings.slice(0, itemsPerPage);

//   return (
//     <>
//       <ToastContainer autoClose={8000} />
//       <div className="container my-4">
//         <header>
//           <div className="d-flex align-items-center justify-content-center mb-2">
//             <img src="/logoteleNew.png" alt="logo" style={{ height: '100px', width: 'auto' }} />
//           </div>
//         </header>
//         <div className="d-flex align-items-center justify-content-between">
//           <button
//             className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal"
//             onClick={backToMeetings}
//           >&#8592; Back</button>
//           <button
//             className="page-link border border-2 border-gray rounded p-1 pr-2 ps-2 fw-normal"
//             onClick={goToScheduleMeetings}
//           >Schedule a meeting &#8594;</button>
//         </div>
//         <h2 className="text-center mb-4">Scheduled Meetings</h2>
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Upcoming' ? 'active' : ''}`} onClick={() => { setActiveTab('Upcoming'); setCurrentPage(1); }}>
//               Upcoming
//             </button>
//           </li>
//           <li className="nav-item">
//             <button className={`nav-link fw-bold ${activeTab === 'Previous' ? 'active' : ''}`} onClick={() => { setActiveTab('Previous'); setCurrentPage(1); }}>
//               Previous
//             </button>
//           </li>
//         </ul>
//         <table className="table table-striped table-bordered text-center mt-3">
//           <thead className="thead-dark">
//             <tr>
//               <th>Patient Name</th>
//               <th>Patient Phone</th>
//               <th>Nurse Name</th>
//               <th>Nurse Phone</th>
//               <th>Schedule</th>
//               <th>Duration</th>
//               <th>Join Meeting</th>
//               <th>Reminder</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentItems.map((meeting) => (
//               <tr key={meeting._id}>
//                 <td>{meeting.patientName}</td>
//                 <td>{meeting.patientPhone}</td>
//                 <td>{meeting.nurseName}</td>
//                 <td>{meeting.nursePhone}</td>
//                 <td>{new Date(meeting.start_time).toLocaleString()}</td>
//                 <td>{meeting.duration} minutes</td>
//                 <td>
//                   <a href={meeting.meetingLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Join Meeting</a>
//                 </td>
//                 <td>
//                   <button onClick={() => sendReminder(meeting._id)} className="btn btn-primary">Send Reminder</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <nav className="d-flex justify-content-center">
//           <ul className="pagination">
//             {[...Array(totalPages)].map((_, index) => (
//               <li key={index + 1} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
//                 <button className="page-link" onClick={() => handleClick(index + 1)}>{index + 1}</button>
//               </li>
//             ))}
//           </ul>
//         </nav>
//         <footer>
//           <div className="d-flex align-items-center justify-content-center mt-4 pt-4">
//             <div>
//               <img src="/footertele.png" alt="logo" style={{ height: '40px', width: 'auto' }} />
//             </div>
//           </div>
//         </footer>
//       </div>
//       <Modal show={showScheduleMeetPopup} onHide={() => setShowScheduleMeetPopup(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Schedule a Meeting</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ScheduleMeet />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowScheduleMeetPopup(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default MeetingsTable;
