// src/hooks/useFetchJWT.js
import { useState, useEffect } from 'react';
import { authEndpoint, role } from '../config';

const useFetchJWT = (sessionName, userName) => {
    const [jwt, setJWT] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (sessionName && userName) {
            fetch(authEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    mode: 'cors',  // Enable CORS mode
                },
                body: JSON.stringify({ sessionName, role }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.signature) {
                        setJWT(data.signature);
                    } else {
                        setError('Failed to fetch JWT');
                    }
                })
                .catch((err) => {
                    setError(err.message);
                });
        }
    }, [sessionName, userName]);

    return { jwt, error };
};

export default useFetchJWT;
