import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './App'; 
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <ToastContainer />
    <App />
 </Router>
); 